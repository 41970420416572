@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  body {
    @apply font-['Inter'] antialiased text-secondary-800 bg-white;
  }
}

@layer components {
  .gradient-bg {
    @apply bg-gradient-to-r from-primary-500 via-primary-400 to-primary-600 animate-gradient-x;
  }
  
  .floating-orb {
    @apply absolute rounded-full bg-gradient-to-r from-primary-300 via-primary-400 to-primary-500 opacity-50 blur-xl animate-float;
  }

  .btn-primary {
    @apply px-6 py-3 bg-primary-600 text-white rounded-lg font-semibold 
           hover:bg-primary-700 transition-all duration-300 
           shadow-lg hover:shadow-xl hover:-translate-y-0.5;
  }

  .btn-secondary {
    @apply px-6 py-3 bg-white text-primary-600 rounded-lg font-semibold 
           border-2 border-primary-600 hover:bg-primary-50 
           transition-all duration-300;
  }

  .card {
    @apply bg-white rounded-xl shadow-lg p-6 hover:shadow-xl 
           transition-all duration-300 hover:-translate-y-1;
  }

  .section-padding {
    @apply py-16 px-4 sm:px-6 lg:px-8;
  }

  .gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r 
           from-primary-600 to-primary-400;
  }
}
